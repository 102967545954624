export class ProductConstants {
    static readonly Medialand: string = "Medialand";
    static readonly Adwanted: string = "Adwanted";
}

export type ProductConstantsType = typeof ProductConstants.Medialand | typeof ProductConstants.Adwanted

export class ApiConstants {
    //static readonly HostApi: string = "http://localhost:3131";
    static readonly HostApi: string = "https://carthage.api.adwanted.com/";
    static readonly ApiFormatsJson: string = "?format=json";
    static readonly APINodeEndpoint: string = "/node";
    static readonly APISupportEndpoint: string = "/support/Code/";
    static readonly APIParutionEndpoint: string = "/parutions/";
    static readonly APISupport: string = "/support/";
    static readonly APIGroup: string = "/group/";
    static readonly APIEtude: string = "/wave/";
    static readonly APIOffers: string = "/offers/medial/";
    static readonly APISupportOJD: string = "/supportOJD/";
    static readonly APISupportOJDMens: string = "/supportOJDMens/";
    static readonly APIEtudeEndpoint: string = "/waves";
    static readonly APIContactsEndpoint: string = "/contacts";
    static readonly APINodeSupportParutionsEndpoint: string = "adwanted/support/parutions/";
    static readonly APINodeSupportCompanyEndpoint: string = "adwanted/support/societe/";
    static readonly APINodeSupportOffersEndpoint: string = "adwanted/support/offers/";
    static readonly LogAPIEndpoint: string = "https://server.dev.adwone.com/ExternalLog/FicheTitre";
}

export class ApiProviderConstants {
    static readonly SupportApiProviderName: string = "supports";
    static readonly ParutionApiProviderName: string = "parutions";
    static readonly EtudeApiProviderName: string = "etudes";
    static readonly AudienceApiProviderName: string = "audiences";
    static readonly OJDApiProviderName: string = "ojd";
    static readonly ContactApiProviderName: string = "contacts"
    static readonly OfferApiProviderName: string = "offers"
    static readonly LogApiProviderName: string = "logs"
}

export type ApiProviderConstantsType =
    typeof ApiProviderConstants.SupportApiProviderName |
    typeof ApiProviderConstants.ParutionApiProviderName |
    typeof ApiProviderConstants.EtudeApiProviderName |
    typeof ApiProviderConstants.AudienceApiProviderName |
    typeof ApiProviderConstants.OJDApiProviderName;

export class QuestionIdsConstants {
    static readonly RegionQuestionId: number = 125;
    static readonly SexeQuestionId: number = 129;
    static readonly AgeQuestionId: number = 3;
    static readonly HabitatQuestionId: number = 33;
    static readonly PCSQuestionId: number = 112;
    static readonly ActifQuestionId: number = 1;
    static readonly SizeFoyerQuestionId: number = 130;
}

export type QuestionIdsConstantsType = 
    typeof QuestionIdsConstants.RegionQuestionId |
    typeof QuestionIdsConstants.SexeQuestionId |
    typeof QuestionIdsConstants.AgeQuestionId |
    typeof QuestionIdsConstants.HabitatQuestionId |
    typeof QuestionIdsConstants.PCSQuestionId |
    typeof QuestionIdsConstants.ActifQuestionId |
    typeof QuestionIdsConstants.SizeFoyerQuestionId

export class MenuIdConstants {
    static readonly BlockContacts: string = "BlockContacts";
    static readonly BlockMap: string = "BlockMap";
    static readonly BlockInfoGenerales: string = "BlockInfoGenerales";
    static readonly BlockAudience: string = "BlockAudience";
    static readonly BlockParutions: string = "BlockParutions";
    static readonly BlockOffers: string = "BlockOffers";
}

export class GroupIdsConstants {
    static GroupIdsByEtude: Map<string, GroupIds> = new Map();

    static init() {
        let OneNext2024S2 = [{ name: "Region", id: 125}, { name: "Sexe", id: 129 }, { name: "Age", id: 3 }, {name: "Habitat (5 classes)", id: 33 }, {name: "PCS individu", id: 112}, {name: "Actif", id: 1}, {name: "Taille du foyer", id: 130}];
        let OneNext2024S1 = [{ name: "Region", id: 125}, { name: "Sexe", id: 129 }, { name: "Age", id: 3 }, {name: "Habitat (5 classes)", id: 33 }, {name: "PCS individu", id: 112}, {name: "Actif", id: 1}, {name: "Taille du foyer", id: 130}];
        let OneNext2023S2 = [{ name: "Region", id: 125}, { name: "Sexe", id: 129 }, { name: "Age", id: 3 }, {name: "Habitat (5 classes)", id: 33 }, {name: "PCS individu", id: 112}, {name: "Actif", id: 1}, {name: "Taille du foyer", id: 130}];
        let OneNext2023S1 = [{ name: "Region", id: 125}, { name: "Sexe", id: 129 }, { name: "Age", id: 3 }, {name: "Habitat (5 classes)", id: 33 }, {name: "PCS individu", id: 112}, {name: "Actif", id: 1}, {name: "Taille du foyer", id: 130}];
        let OneNext2022S2 = [{ name: "Region", id: 125}, { name: "Sexe", id: 129 }, { name: "Age", id: 3 }, {name: "Habitat (5 classes)", id: 33 }, {name: "PCS individu", id: 112}, {name: "Actif", id: 1}, {name: "Taille du foyer", id: 130}];
        let OneNext2022V1 = [{ name: "Region", id: 125}, { name: "Sexe", id: 129 }, { name: "Age", id: 3 }, {name: "Habitat (5 classes)", id: 33 }, {name: "PCS individu", id: 112}, {name: "Actif", id: 1}, {name: "Taille du foyer", id: 130}];
        let OneNext2021V4 = [{ name: "Region", id: 125}, { name: "Sexe", id: 129 }, { name: "Age", id: 3 }, {name: "Habitat (5 classes)", id: 33 }, {name: "PCS individu", id: 112}, {name: "Actif", id: 1}, {name: "Taille du foyer", id: 130}];
        let OneNext2021V3 = [{ name: "Region", id: 125}, { name: "Sexe", id: 129 }, { name: "Age", id: 3 }, {name: "Habitat (5 classes)", id: 33 }, {name: "PCS individu", id: 112}, {name: "Actif", id: 1}, {name: "Taille du foyer", id: 130}];
        let OneNext2021V2 = [{ name: "Region", id: 125}, { name: "Sexe", id: 129 }, { name: "Age", id: 3 }, {name: "Habitat (5 classes)", id: 33 }, {name: "PCS individu", id: 112}, {name: "Actif", id: 1}, {name: "Taille du foyer", id: 130}];
        let OneNext2021V1 = [{ name: "Region", id: 125}, { name: "Sexe", id: 129 }, { name: "Age", id: 3 }, {name: "Habitat (5 classes)", id: 33 }, {name: "PCS individu", id: 112}, {name: "Actif", id: 1}, {name: "Taille du foyer", id: 130}];
        let ONENEXT2020V4 = [{ name: "Region", id: 125}, { name: "Sexe", id: 129 }, { name: "Age", id: 3 }, {name: "Habitat (5 classes)", id: 33 }, {name: "PCS individu", id: 112}, {name: "Actif", id: 1}, {name: "Taille du foyer", id: 130}];
        let ONENEXT2020V2 = [{ name: "Region", id: 125}, { name: "Sexe", id: 129 }, { name: "Age", id: 3 }, {name: "Habitat (5 classes)", id: 33 }, {name: "PCS individu", id: 112}, {name: "Actif", id: 1}, {name: "Taille du foyer", id: 130}];
        let ONENEXT2020V1 = [{ name: "Region", id: 125}, { name: "Sexe", id: 129 }, { name: "Age", id: 3 }, {name: "Habitat (5 classes)", id: 33 }, {name: "PCS individu", id: 112}, {name: "Actif", id: 1}, {name: "Taille du foyer", id: 130}];
        let ONE2017 = [{ name: "Region", id: 125}, { name: "Sexe", id: 129 }, { name: "Age", id: 3 }, {name: "Habitat (5 classes)", id: 33 }, {name: "PCS individu", id: 112}, {name: "Actif", id: 1}, {name: "Taille du foyer", id: 130}];

        // Carte ?
        let OneNextGlobal2021V3 = [{ name: "Region", id: 125}, { name: "Sexe", id: 129 }, { name: "Age", id: 3 }, {name: "Habitat (5 classes)", id: 33 }, {name: "PCS individu", id: 112}, {name: "Actif", id: 1}, {name: "Taille du foyer", id: 130}];
        let OneNextGlobal2021V2 = [{ name: "Region", id: 125}, { name: "Sexe", id: 129 }, { name: "Age", id: 3 }, {name: "Habitat (5 classes)", id: 33 }, {name: "PCS individu", id: 112}, {name: "Actif", id: 1}, {name: "Taille du foyer", id: 130}];
        let OneNextGlobal2021V1 = [{ name: "Region", id: 125}, { name: "Sexe", id: 129 }, { name: "Age", id: 3 }, {name: "Habitat (5 classes)", id: 33 }, {name: "PCS individu", id: 112}, {name: "Actif", id: 1}, {name: "Taille du foyer", id: 130}];
        let ONENEXTGLOBAL2020V4 = [{ name: "Region", id: 125}, { name: "Sexe", id: 129 }, { name: "Age", id: 3 }, {name: "Habitat (5 classes)", id: 33 }, {name: "PCS individu", id: 112}, {name: "Actif", id: 1}, {name: "Taille du foyer", id: 130}];
        let ONENEXTGLOBAL2020V2 = [{ name: "Region", id: 125}, { name: "Sexe", id: 129 }, { name: "Age", id: 3 }, {name: "Habitat (5 classes)", id: 33 }, {name: "PCS individu", id: 112}, {name: "Actif", id: 1}, {name: "Taille du foyer", id: 130}];
        let ONENEXTGLOBAL2020V1 = [{ name: "Region", id: 125}, { name: "Sexe", id: 129 }, { name: "Age", id: 3 }, {name: "Habitat (5 classes)", id: 33 }, {name: "PCS individu", id: 112}, {name: "Actif", id: 1}, {name: "Taille du foyer", id: 130}];
        
        // pas importé kekw
        //let ONENextGlobal2019 = [{ name: "Region", id: 125}, { name: "Sexe", id: 129 }, { name: "Age", id: 3 }, {name: "Habitat (5 classes)", id: 33 }, {name: "PCS individu", id: 112}, {name: "Actif", id: 1}, {name: "Taille du foyer", id: 130}];
        let OneNextInfluence2024 = [{ name: "Region", id: 125}, { name: "Sexe", id: 129 }, { name: "Age", id: 235 }, {name: "Habitat (5 classes)", id: 33 }, {name: "PCS individu", id: 112}, {name: "Actif", id: 1}, {name: "Taille du foyer", id: 130}];
        let OneNextInfluence2023 = [{ name: "Region", id: 125}, { name: "Sexe", id: 129 }, { name: "Age", id: 235 }, {name: "Habitat (5 classes)", id: 33 }, {name: "PCS individu", id: 112}, {name: "Actif", id: 1}, {name: "Taille du foyer", id: 130}];
        let OneNextInfluence2022 = [{ name: "Region", id: 125}, { name: "Sexe", id: 129 }, { name: "Age", id: 235 }, {name: "Habitat (5 classes)", id: 33 }, {name: "PCS individu", id: 112}, {name: "Actif", id: 1}, {name: "Taille du foyer", id: 130}];
        let OneNextInfluence2021 = [{ name: "Region", id: 125}, { name: "Sexe", id: 129 }, { name: "Age", id: 235 }, {name: "Habitat (5 classes)", id: 33 }, {name: "PCS individu", id: 112}, {name: "Actif", id: 1}, {name: "Taille du foyer", id: 130}];
        let ONENextPremium2020V1 = [{ name: "Region", id: 125}, { name: "Sexe", id: 129 }, { name: "Age", id: 235 }, {name: "Habitat (5 classes)", id: 33 }, {name: "PCS individu", id: 112}, {name: "Actif", id: 1}, {name: "Taille du foyer", id: 130}];
        let ONENextPremiumGlobal2020V1 = [{ name: "Region", id: 125}, { name: "Sexe", id: 129 }, { name: "Age", id: 235 }, {name: "Habitat (5 classes)", id: 33 }, {name: "PCS individu", id: 112}, {name: "Actif", id: 1}, {name: "Taille du foyer", id: 130}];
        let OnePremium2017 = [{ name: "Region", id: 125}, { name: "Sexe", id: 129 }, { name: "Age", id: 235 }, {name: "Habitat (5 classes)", id: 33 }, {name: "PCS individu", id: 112}, {name: "Actif", id: 1}, {name: "Taille du foyer", id: 130}];

        //let ONI20 = [{ name: "Region", id: 125}, { name: "Sexe", id: 1808 }, { name: "Age", id: 3 }, {name: "Habitat regroupé (signaletique principaux s.d.)", id: 3916 }, {name: "PCS individu", id: 1726}, {name: "Actif", id: 1}, {name: "Taille du foyer", id: 130}];
        //let TGI20R2 = [{ name: "Region", id: 125}, { name: "Sexe", id: 1808 }, { name: "Age", id: 3 }, {name: "Habitat regroupé (signaletique principaux s.d.)", id: 3916 }, {name: "PCS individu", id: 1726}, {name: "Actif", id: 1}, {name: "Taille du foyer", id: 130}];

        this.GroupIdsByEtude.set("OneNext2024S2", new GroupIds(OneNext2024S2));
        this.GroupIdsByEtude.set("OneNext2024S1", new GroupIds(OneNext2024S1));
        this.GroupIdsByEtude.set("OneNext2023S2", new GroupIds(OneNext2023S2));
        this.GroupIdsByEtude.set("OneNext2023S1", new GroupIds(OneNext2023S1));
        this.GroupIdsByEtude.set("OneNext2022S2", new GroupIds(OneNext2022S2));
        this.GroupIdsByEtude.set("OneNext2022V1", new GroupIds(OneNext2022V1));
        this.GroupIdsByEtude.set("OneNext2021V4", new GroupIds(OneNext2021V4));
        this.GroupIdsByEtude.set("OneNext2021V3", new GroupIds(OneNext2021V3));
        this.GroupIdsByEtude.set("OneNext2021V2", new GroupIds(OneNext2021V2));
        this.GroupIdsByEtude.set("OneNext2021V1", new GroupIds(OneNext2021V1));
        this.GroupIdsByEtude.set("ONENEXT2020V4", new GroupIds(ONENEXT2020V4));
        this.GroupIdsByEtude.set("ONENEXT2020V2", new GroupIds(ONENEXT2020V2));
        this.GroupIdsByEtude.set("ONENEXT2020V1", new GroupIds(ONENEXT2020V1));
        this.GroupIdsByEtude.set("OneNextGlobal2021V3", new GroupIds(OneNextGlobal2021V3));
        this.GroupIdsByEtude.set("OneNextGlobal2021V2", new GroupIds(OneNextGlobal2021V2));
        this.GroupIdsByEtude.set("OneNextGlobal2021V1", new GroupIds(OneNextGlobal2021V1));
        this.GroupIdsByEtude.set("ONENEXTGLOBAL2020V4", new GroupIds(ONENEXTGLOBAL2020V4));
        this.GroupIdsByEtude.set("ONENEXTGLOBAL2020V2", new GroupIds(ONENEXTGLOBAL2020V2));
        this.GroupIdsByEtude.set("ONENEXTGLOBAL2020V1", new GroupIds(ONENEXTGLOBAL2020V1));
        //this.GroupIdsByEtude.set("ONENextGlobal2019", new GroupIds(ONENextGlobal2019));
        this.GroupIdsByEtude.set("OneNextInfluence2024", new GroupIds(OneNextInfluence2024));
        this.GroupIdsByEtude.set("OneNextInfluence2023", new GroupIds(OneNextInfluence2023));
        this.GroupIdsByEtude.set("OneNextInfluence2022", new GroupIds(OneNextInfluence2022));
        this.GroupIdsByEtude.set("OneNextInfluence2021", new GroupIds(OneNextInfluence2021));
        this.GroupIdsByEtude.set("ONENextPremium2020V1", new GroupIds(ONENextPremium2020V1));
        this.GroupIdsByEtude.set("ONENextPremiumGlobal2020V1", new GroupIds(ONENextPremiumGlobal2020V1));
        this.GroupIdsByEtude.set("ONE2017", new GroupIds(ONE2017));
        this.GroupIdsByEtude.set("OnePremium2017", new GroupIds(OnePremium2017));
        //this.GroupIdsByEtude.set("TGI20R2", new GroupIds(TGI20R2));
        //this.GroupIdsByEtude.set("ONI20", new GroupIds(ONI20));
    }
}

export class GroupIds {
    public ids: {name: string, id: number}[] = [];

    public constructor(ids) {
        this.ids = ids;
    }
}

export class ColorsConstant {
    static readonly ColorsByProduct: Map<ProductConstantsType, ThemeColors> = new Map();

    static init() {
        this.ColorsByProduct.set(ProductConstants.Adwanted, new ThemeColors(
            "rgb(229,38,32)",
            "rgb(210,210,210)",
            "rgba(201,39,34,0.5)",
            "rgba(201,39,34,1)",
            "201",
            "39",
            "34",
            [
                '#E52620',
                '#EE7470',
                '#F4A4A1',
                '#F8C6C4',
                '#FBE0DF',
                '#EC605B',
                '#921511',
                '#F29591'
            ]
        ));
        this.ColorsByProduct.set(ProductConstants.Medialand, new ThemeColors(
            "rgb(15,155,120)",
            "rgb(210,210,210)",
            "rgba(1,135,71,0.5)",
            "rgba(1,135,71,1)",
            "1",
            "135",
            "71",
            [
                '#0F9B78',
                '#8FCFB0',
                '#B8D1C5',
                '#52CF93',
                '#73CFA3',
                '#A4E0C3',
                '#2A7A52',
                '#39A770'
            ]
        ));
    }
}

export class ThemeColors {
    public ColorLastGraph: string;
    public ColorGraph: string;
    public ColorMin: string;
    public ColorMax: string;
    public ColorR: string;
    public ColorG: string;
    public ColorB: string;
    public ColorGradient: string[];

    public constructor(ColorLastGraph: string, ColorGraph: string, ColorMin: string, ColorMax: string, ColorR: string, ColorG: string, ColorB: string, ColorGradient: string[]) {
        this.ColorLastGraph = ColorLastGraph;
        this.ColorGraph = ColorGraph;
        this.ColorMin = ColorMin;
        this.ColorMax = ColorMax;
        this.ColorR = ColorR;
        this.ColorG = ColorG;
        this.ColorB = ColorB;
        this.ColorGradient = ColorGradient;
    }
}

export class AdwantedImageUrls {
    static readonly CouvertureUrl = "https://www.mediasbook.com/public/shared/img/offers/";
    static readonly MediaBrandUrl = "https://www.mediasbook.com/public/shared/img/medias/";
}

export class PublicPicturesUrls {
    static readonly ImageOjdOne = "/pictures/ojdone.png";
    static readonly ImageOjd = "/pictures/ojd.png";
}